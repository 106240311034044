<template>
    <v-app class="default">
        <router-view />
        <v-footer app color="transparent" padless>
            <v-row justify="center" no-gutters>
                <v-col
                    class="white--text py-2 text-center"
                    cols="12"
                    sm="4"
                    lg="2"
                >
                    &copy; {{ year_copyright }} —
                    <strong>BEN digital services GmbH</strong>
                </v-col>
                <v-col
                    v-for="(link, index) in footerLinks"
                    :key="index"
                    class="py-2 text-center"
                    cols="6"
                    sm="3"
                    md="2"
                    lg="1"
                >
                    <router-link
                        flat
                        :ripple="false"
                        :to="link.to"
                        class="white--text"
                    >
                        {{ link.title }}
                    </router-link>
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    data() {
        const d = new Date();
        return {
            fixed: false,
            year_copyright: d.getFullYear(),
            footerLinks: [
                {
                    title: this.$i18n.t('footer.imprint'),
                    to: { name: 'Imprint' },
                },
                {
                    title: this.$i18n.t('footer.privacy'),
                    to: { name: 'Privacy' },
                },
            ],
        };
    },
};
</script>
